<route>
{
  "meta": {
    "permission": [
      "sales.view_order"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="code"
          :name="$t('code')"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.code"
            outlined
            :error-messages="errors[0]"
            :label="$t('code')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="name"
          :name="$t('name')"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.name"
            outlined
            :error-messages="errors[0]"
            :label="$t('name')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="3">
        <ValidationProvider
          vid="status"
          :name="$t('status')"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="form.status"
            outlined
            :error-messages="errors[0]"
            :label="$t('status')"
            class="secondary--text"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <i-table
      :title="$t('salesReport')"
      :headers="headers"
      app="sales.order"
      api="sale.product"
      :opt="{
        code: this.form.code,
        name: this.form.name,
        status: this.form.status
      }"
      dontCreate
      dontRemove
      dontEdit
      dontSearch
    >
      <template v-slot:extraToolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <i-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          icon="fa-file-excel"
          :title="$t('download', { value: $t('salesReport') })"
          color="text-dark"
          @click="getSaleOrdersExcel()"
        >
        </i-btn>
      </template>
    </i-table>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        code: '',
        status: ''
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('order_code'),
          value: 'code'
        },
        {
          text: this.$t('order_name'),
          value: 'projectName'
        },
        {
          text: this.$t('order_status'),
          value: 'orderMain'
        },
        {
          text: this.$tc('customer', 1),
          value: 'customerName'
        },
        {
          text: this.$t('dateOrder'),
          value: 'orderDate'
        },
        {
          text: this.$t('promise_date'),
          value: 'promiseDate'
        },
        {
          text: this.$tc('salesman', 1),
          value: 'salesmanName'
        },
        {
          text: this.$tc('product', 1),
          value: 'own_prod'
        },
        {
          text: this.$tc('price', 1),
          value: 'price'
        },
        {
          text: this.$tc('quantity', 1),
          value: 'quantity'
        },
        {
          text: this.$tc('subtotal', 1),
          value: 'subtotal'
        }
      ]
    }
  },
  methods: {
    getSaleOrdersExcel() {
      this.downloadFile({
        api: 'sale.product',
        method: 'excel',
        filename: 'SaleOrders',
        action: 'download',
        ext: 'xlsx',
        opt: {
          pk: 1,
          params:
            '?name=' +
            this.form.name +
            '&code=' +
            this.form.code +
            '&status=' +
            this.form.status
        }
      })
    }
  }
}
</script>
